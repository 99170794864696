import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import IContentfulProduct from '../../interfaces/ContentfulProduct';
import IContentfulProductLine from '../../interfaces/ContentfulProductLine';
import AnalyticsService from '../../services/analytics';
import IfuProductInfo from '../ifu-product-info/ifu-product-info';

const getProductLines = graphql`
  query {
    productLines: allContentfulProductLine(sort: { fields: updatedAt }) {
      nodes {
        id
        name
        slug
        products {
          name
          id
          ifuCode
          slug
        }
      }
    }
  }
`;

const InstructionsHome = () => {
  const [ifuCode, setifuCode] = useState('');
  const { productLines } = useStaticQuery(getProductLines);
  const [selectedProductSlug, setselectedProductSlug] = useState('');
  const [productLine, setproductLine] = useState('');
  const [ifuCodeError, setIfuCodeError] = useState(false);
  const [Analytics, setAnalytics] = useState();

  useEffect(() => {
    setAnalytics(new AnalyticsService());
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    productLines.nodes.map((productline: IContentfulProductLine) => {
      setIfuCodeError(false);
      if (productline.products) {
        productline.products.map((product: IContentfulProduct) => {
          if (product.ifuCode === event.target.value) {
            setselectedProductSlug(product.slug);
            setproductLine(productline.slug);
          }
        });
      }
    });
    setifuCode(event.target.value);
  };

  const searchIfu = () => {
    if (productLine && selectedProductSlug) {
      navigate(`${productLine}/${selectedProductSlug}`);
    } else {
      setIfuCodeError(true);
    }
    Analytics.track('IFU Code Searched', {
      path: window.location.href,
      searchTerm: ifuCode,
    });
  };

  return (
    <section>
      <div className="container mt-3">
        <h2 className="pb-6">Instructions for use</h2>
        <div className="pb-8">
          <h4>Enter IFU Code</h4>
          <p>
            We need the IFU code to locate the corresponding IFU for your product. The IFU code is located on the
            package label
          </p>
          <label>IFU Code</label>
          <div className="d-flex flex-row w-100">
            <input onChange={handleInputChange} value={ifuCode} className="border border-light-gray p-2" />
            <button onClick={searchIfu} className="btn btn-ifu ifu-filters-blocks link-plain pl-2 ml-3">
              Find IFU
            </button>
          </div>
          {ifuCodeError && <div className="p-2 text-danger">Invalid IFU Code</div>}
        </div>
      </div>

      <IfuProductInfo productLines={productLines.nodes} />
    </section>
  );
};

export default InstructionsHome;
