import { Link } from 'gatsby';
import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import IContentfulProduct from '../../interfaces/ContentfulProduct';
import IContentfulProductLine from '../../interfaces/ContentfulProductLine';
import styles from './ifu-product-info.module.scss';

interface IContentfulProductLineList {
  productLines: IContentfulProductLine[];
}

const IfuProductInfo: React.FC<IContentfulProductLineList> = ({ productLines }: IContentfulProductLineList) => {
  if (!productLines || productLines.length === 0) {
    return null;
  }

  return (
    <>
      <div className="py-5 container px-0 px-md-3 d-lg-none">
        <h4 className="px-3 px-md-0">Select Product Line</h4>
        <div className="filter-block">
          {productLines.map((node: IContentfulProductLine, index: number) => {
            return (
              <Link
                key={`product-line-${index}`}
                to={`/${node.slug}`}
                className="ifu-filters-blocks link-plain px-3 px-md-0">
                <span>{node.name}</span>
                <span>
                  <IoIosArrowForward className="icon" />
                </span>
              </Link>
            );
          })}
        </div>
      </div>
      <div className={`${styles.productLineContainer} d-lg-flex d-none`}>
        <div className="container">
          <div className="row py-3">
            {productLines.map((productLine: IContentfulProductLine, index: number) => (
              <div key={`product-line-${index}`} className={`${styles.productLineBlock} col-md-4 py-3`}>
                <h4 className="pb-3 m-0">{productLine.name}</h4>
                {productLine.products &&
                  productLine.products.map((product: IContentfulProduct, i: number) => (
                    <div className="pb-3" key={`product-${i}`}>
                      <Link className={`${styles.productLink} link-plain`} to={`${productLine.slug}/${product.slug}`}>
                        {product.name}
                      </Link>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default IfuProductInfo;
