import React from 'react';
import InstructionsHome from '../components/instructions-home/instructions-home';
import SEO from '../components/seo';

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <InstructionsHome />
    </>
  );
};

export default IndexPage;
